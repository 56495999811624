<template>
  <div class="pa-9">
    <h1>
      Cấu hình trang chính sách hỗ trợ
    </h1>
    <ValidationObserver ref="add_question">
      <div>
        <h4>Tiêu đề</h4>
        <ValidationProvider
            :name="'title'"
            rules="required"
            v-slot="{ errors }"
        >
          <Input
              :name="'title'"
              v-model="setting.title"
              :text="setting.title"
              :label="'Nhập tiêu đề'"
          />
          <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
        </ValidationProvider>
        <h4>Key tiêu đề</h4>
        <ValidationProvider
            :name="'seo_title'"
            rules="required"
            v-slot="{ errors }"
        >
          <Input
              :name="'seo_title'"
              v-model="setting.seo_title"
              :text="setting.seo_title"
              :label="'Nhập key tiêu đề'"
          />
          <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
        </ValidationProvider>
        <h4>Key nội dung</h4>
        <ValidationProvider
            :name="'seo_description'"
            rules="required"
            v-slot="{ errors }"
        >
          <Input
              :name="'seo_description'"
              v-model="setting.seo_description"
              :text="setting.seo_description"
              :label="'Nhập key nội dung'"
          />
          <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
        </ValidationProvider>
        <h4>Nội dung</h4>
        <CkContent
            validateName="question"
            :content="setting.content"
            @getData="(value) => setting.content = value"
        ></CkContent>
      </div>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4">
        <v-btn color="primary" @click="saveConfig">{{ save }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {GET_CONFIG,SAVE_CONF} from "@/store/setting.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  computed: {
    ...mapGetters({
      setting: "setting"
    })
  },
  data() {
    return {
      save: 'Lưu'
    }
  },
  components: {
    CkContent: () => import("@/components/ck-content"),
    Input: () => import("@/components/elements/hnr-input")
  },
  mounted() {
    let key = {
      'key': 'support'
    }
    this.$store.dispatch(GET_CONFIG, key);
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cấu hình hỗ trợ",titledad:'Cấu hình' }]);
  },
  methods: {
    async saveConfig() {
      let result = await this.$refs.add_question.validate()
      if (result) {
      if (!this.setting.content){
        this.$toasted.error("Nội dung không được để trống !!", {
          position: "top-right",
          duration : 3000
        })
        return false
      }
      let payload = {
        key : 'support',
        value : this.setting
      }
      this.$store.dispatch(SAVE_CONF, payload).then(data => {
        if (data.status) {
          this.$toasted.success('Lưu thành công', {
            position: "top-right",
            theme: "toasted-primary",
            duration : 1000
          });
        }
      });
      }else {
        this.$toasted.error("Bạn chưa nhập đủ thông tin !!", {
          position: "top-right",
          duration : 3000
        })
        return false
      }
    }
  }
}
</script>